import React, { useState, useEffect, useCallback } from "react";
import { Nav, Navbar, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBell } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import ModalCreateProject from "./ModalCreateProject";
import ModalCreateResource from "./ModalCreateResource";
import { useSubscription } from "react-stomp-hooks";
import "../../i18n";
import { timeslotsApi } from "../../misc/TimeslotsApi";

const Header = () => {
  
  const { t } = useTranslation();
  const { isAuthenticated, kc, handleLogin, handleLogout, token, updateToken } =
    useAuth();
  const userEmail = kc.tokenParsed?.email;
  const userRoles = kc.tokenParsed?.realm_access?.roles || [];
  const userId = kc.tokenParsed?.sub;
  const isAdmin = userRoles.includes("admin-role");
  const isManager = userRoles.includes("manager-role");
  const { i18n } = useTranslation();
  const [showModalCreateProject, setShowModalCreateProject] = useState(false);
  const [showModalCreateResource, setShowModalCreateResource] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };

  const openModalCreateProject = () => setShowModalCreateProject(true);
  const closeModalCreateProject = () => setShowModalCreateProject(false);
  const openModalCreateResource = () => setShowModalCreateResource(true);
  const closeModalCreateResource = () => setShowModalCreateResource(false);

  const translateNotification = useCallback(
    (key) => t(key, key), 
    [t]
  );

  useSubscription(`/queue/reply-${kc.tokenParsed?.sub}`, (message) => {
    const notification = JSON.parse(message.body);
    notification.translatedMessage = translateNotification(notification.message);
    setNotifications((prev) => {
      const exists = prev.some((n) => n.id === notification.id);
      const updatedNotifications = exists ? prev : [notification, ...prev];
      return updatedNotifications.slice(0, 20); 
    });
  });

  useEffect(() => {
    const fetchNotifications = async () => {
      await updateToken();
      if (token) {
        timeslotsApi
          .getUnreadNotificationsByRecipient(token, userId)
          .then((response) => {
            const translatedNotifications = response.data.map((notification) => ({
              ...notification,
              translatedMessage: translateNotification(notification.message),
            })).slice(0, 20);
            setNotifications(translatedNotifications);
          })
          .catch((error) => {
            console.error("Error while loading notifications:", error);
          });
      }
    };
    fetchNotifications();
  }, [token, updateToken, userId, translateNotification]);

  const handleNotificationClick = async (notification) => {
    if (notification.read) return;
    try {
      await updateToken();
      if (token) {
        await timeslotsApi.updateNotification(
          notification.id,
          { ...notification, read: true },
          token
        );
        setNotifications((prev) =>
          prev.map((n) => (n.id === notification.id ? { ...n, read: true } : n))
        );
      }
    } catch (error) {
      console.error(`Failed to update notification ${notification.id}:`, error);
    }
    if (notification.action) {
      navigate(notification.action);
    }
  };

  const unreadCount = notifications.filter((n) => !n.read).length;

  return (
    <>
      <Navbar
        id="navbar"
        expand="lg"
        fixed="top"
        variant="dark"
        className="header px-4 py-3"
      >
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="me-auto text-center">
            {isAuthenticated && isAdmin && (
              <>
                <Nav.Link
                  className="btn navbar-text text-white"
                  onClick={openModalCreateProject}
                >
                  {t("projectCreation")}
                </Nav.Link>
                <Nav.Link
                  className="btn navbar-text text-white"
                  onClick={openModalCreateResource}
                >
                  {t("resourceCreation")}
                </Nav.Link> 
	    </>
	    )}

                {isAuthenticated && isManager && (
			<>
                  <Nav.Link
                    as={Link}
                    to="/timesheetApproval"
                    className="btn navbar-text text-white"
                  >
                    {t("timesheetApproval")}
                  </Nav.Link>
			</>
                )}         
         </Nav>
	  <Nav className="ms-auto text-center">
            {isAuthenticated ? (
              <>
                {userEmail && (
                  <span className="navbar-text text-white mx-2">
                    <FontAwesomeIcon icon={faUser} className="mx-2" />
                    {userEmail}
                  </span>
                )}
                <Nav.Link
                  className="btn navbar-text text-white"
                  onClick={handleLogout}
                >
                  Logout
                </Nav.Link>
              </>
            ) : (
              <Nav.Link
                className="btn navbar-text text-white"
                onClick={handleLogin}
              >
                Login
              </Nav.Link>
            )}
            <Nav.Link
              className="btn navbar-text text-white"
              onClick={() => changeLanguage("en")}
            >
              English
            </Nav.Link>
            <Nav.Link
              className="btn navbar-text text-white"
              onClick={() => changeLanguage("it")}
            >
              Italiano
            </Nav.Link>
            <Dropdown align="end">
              <Dropdown.Toggle
                as={Nav.Link}
                className="btn navbar-text text-white position-relative"
              >
                <FontAwesomeIcon icon={faBell} />
                {unreadCount > 0 && (
                  <span className="badge bg-danger position-absolute top-0 start-100 translate-middle">
                    {unreadCount > 20 ? "20+" : unreadCount}
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {notifications.map((notification) => (
                  <Dropdown.Item
                    key={notification.id}
                    onClick={() => handleNotificationClick(notification)}
                    className={notification.read ? "notification-item" : "notification-item unread"}
                  >
                    {notification.translatedMessage || notification.message}
                  </Dropdown.Item>
                ))}
                {notifications.length === 0 && (
                  <Dropdown.Item className="text-center text-muted">
                    {t("noNotifications")}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ModalCreateProject
        show={showModalCreateProject}
        handleClose={closeModalCreateProject}
      />
      <ModalCreateResource
        show={showModalCreateResource}
        handleClose={closeModalCreateResource}
      />
    </>
  );
};

export default Header;
